/* Declaracion de Fonts locales */
@font-face {
  font-family: 'RobotoCondensed-Regular';
  src:
      local('RobotoCondensed-Regular'),
      url('../fonts/RobotoCondensed-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'ComicNeue-Regular';
  src:
      local('ComicNeue-Regular'),
      url('../fonts/Comic_Neue/ComicNeue-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'ReenieBeanie-Regular';
  src:
      local('ReenieBeanie-Regular'),
      url('../fonts/ReenieBeanie-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'PPETRIAL';
  src:
      local('PPETRIAL'),
      url('../fonts/Tiza/PPETRIAL.ttf') format('truetype');
}

body {
  margin: 0;
  font-family: 'ComicNeue-Regular' !important;
  /* font-family: 'RobotoCondensed-Regular' !important; */
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

code {
  font-family: 'RobotoCondensed-Regular' !important;
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace; */
}
