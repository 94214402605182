html {
  min-height: 100%;
  position: relative;
}
body {
  margin: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #a0906f !important;
  /* color: #8fb464 !important; */

  text-align: left;
  background-color: rgb(0, 0, 0) !important;
}

u {
  text-align: center;
  color: hsl(163deg 75% 68% / 77%);  /*ff7d20fc*/
}

h1 {
  text-align: center;
  color: #ff205f;
}

h5 {
  padding-bottom: 10px;
  color:#ff205f;
}

/*
----------------------
  Section Navbar
----------------------
*/
.logo {
  width: 55px ;
  height: 30px;  
}
.bg-dark-black {
  background-color: #000000 !important;
}

.navbar-dark .navbar-brand {
  color: #a0906f !important;
}

.navbar-dark .navbar-nav .nav-link {
  color: #a0906f !important;
}

@media screen and (max-width: 800px){
  .nav-link {
    text-align: center;
  }
}


/*
----------------------
  Section Slider
----------------------
*/

.slider-properties {
  width: 100%;
}

.carousel-caption {
  text-shadow: 1px 1px rgb(241, 209, 131);
  color: #2b2b2b !important;
}

/* .carousel-caption h3 {
  position: absolute !important;
  top: -15em;  
} */
.carousel-caption  {
  font-family: 'ReenieBeanie-Regular' !important;
  color: #6ba7ae !important;
}
.carousel-caption p {
  font-family: 'ReenieBeanie-Regular' !important;
  color: #6ba7ae !important; 
  text-shadow: 1px 1px rgb(255, 255, 255);
  font-size: 36px !important;
}
@media screen and (max-width: 800px){
  .slider-properties {
    /* width: 230%!important; */
    width: 100% !important;
    height: 310px !important;
  }
  .carousel-caption {
    top: 56px;
  }  
  .carousel-caption h3 {
    position: absolute;
    top: -4em;
    font-size: 12px;
  }  
  .carousel-caption p {
    position: absolute !important;
    top: 4em !important;    
    font-size: 24px;
    line-height: 31px;
  }  
}

/*
----------------------
  Section Information
----------------------
*/

.section-information{
  padding-top: 45px;
  padding-bottom: 25px;
  background-color: rgb(0, 0, 0);
}

.section-information a{
  color:#ff205f !important;
  text-decoration: underline;
  animation-name: parpadeo;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;

  -webkit-animation-name:parpadeo;
  -webkit-animation-duration: 2s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
}
/* Icono Educacion Social */
.fa-group:before, .fa-users:before {
  content: "\f0c0";
  color: #a0906f;
  padding-right: 5px;
}
/* Icono Revolucion Digital */
.fa-video-camera:before {
  content: "\f03d";
  color: #a0906f;
  padding-right: 5px;  
}
/* Icono Herramientas Tecnologicas */
.fa-wrench:before {
  content: "\f0ad";
  color: #a0906f;
  padding-right: 5px; 
}

@media screen and (max-width: 500px){
  .section-information {
    text-align: center;
  }
}

/*
------------------------------
  Section Reflextion Tecnology
------------------------------
*/

.section-reflection-on-tecnology {
  padding-top: 35px;
  padding-bottom: 25px;
  background-color: #1c1c1f !important; /*e1e1e1*/
  text-align: center;
}

.section-reflection-on-tecnology h2 {
  color: #6ba7ae;  /*23667c*/
}


/*
----------------------
  Section Portfolio
----------------------
*/

/* body .section_porfolio{
  color: #8fb464 !important;
} */

.section_porfolio{
  color: #8fb464 !important;
  padding-bottom: 20px;
  padding-top: 45px;   
  background-color: #000000; 
}

.section_porfolio a {
  /* color: #95d3da !important; */
  text-decoration: underline;
  animation-name: parpadeo;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;

  -webkit-animation-name:parpadeo;
  -webkit-animation-duration: 2s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;  
}
.portfolio-text {
  font-size: 14px;
}
.portfolio-img {
  margin: 5px 5px 5px 0px;
  width:250px;
  height:220px;
  border-radius: 1%;  
}

/*
-----------------------------
  Seccion Reflexion Education
-----------------------------
*/

.section-reflection-on-education{
  padding-bottom: 40px;
  padding-top: 40px;
  background-color: #1c1c1f !important; 
}

.title-education-center{
  text-align: center;
  color: #6ba7ae;
}

.img-education {
  display: block;
  max-width: 100%;
  height: auto;
}

.section-reflection-on-education .strong {
  font-family: 'ReenieBeanie-Regular' !important;
  color: #6ba7ae;
  font-size: 26px;
}

@media screen and (max-width: 500px){
  .section-reflection-on-education h2 {
    text-align: center;
  }
}

/*
-----------------------------
 Section Links
----------------------------- 
*/

.links-interes {
  background-image: url(../../assets/images/pizarron.jpg);
  background-repeat: no-repeat;  
  background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover; 
  height: 320px;
  max-height: 320px;
  font-family: 'PPETRIAL' !important;
  font-size: 20px;
  color: #a0906f !important;
}
.links-interes .item-pagina{
  /* color:rgba(179, 101, 103, 1);  */
  color: #a0906f;
}
.links-interes .item-alumnos{
  /* color:rgba(179, 101, 103, 1);  */
  color: #ffc107;
  opacity: 0.7; 
}
.links-titulo{
  font-family: 'ReenieBeanie-Regular' !important;
  padding-top: 30px;
  padding-bottom: 30px;
  color: #6ba7ae;
  text-align: center;
}
.links-txt-center{
  text-align: center;
}

@media screen and (max-width: 500px){
  .links-interes {
    /* font-size: 16px; */
    letter-spacing: -0.06em !important;
  }
}


/*
-----------------------------
 Section About
----------------------------- 
*/
.about-ancla {
  position: relative;
}
.about-background-txt{
  position: absolute;
  transform: translate(0%,10%);
  width: 100% !important;
}
.about-text-profesion{
  padding-left: 20% !important;
}
.about-text-conocimiento{
  padding-left: 10% !important;
}
.about-background-img {
  height: 500px;
  max-height: 500px;
  background-image: url(../../assets/images/about34.jpg);
  background-position: 0% 35% ;
  background-repeat: no-repeat;  
  background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover; 
  opacity: 0.1;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #000000;
  line-height : 20px;
}
.title-about-center{
  /* font-family: 'ReenieBeanie-Regular' !important; */
  padding-top: 30px;
  padding-bottom: 30px;
  color: #a0906f;
  text-align: center;
  text-decoration: underline;
  
}
.texto-azul {
  font-family: 'ReenieBeanie-Regular' !important;
  color: #6ba7ae;
  padding-top: 30px;
  font-size: 24px;
  font-style: italic;  
  text-align: center;
  line-height : 16px;
  letter-spacing: -0.03em !important;
}


@media screen and (max-width: 500px){
  .about-background-img {
    height: 620px !important;
    max-height: 620px !important;    
    opacity: 0.15 !important;
  }  
  .about-background-txt{
    position: absolute;
    transform: translate(1%,5%) !important;
  }  
  .about-text-profesion{
    padding-left: 6% !important;
    letter-spacing: -0.02em !important;
  }
  .about-text-conocimiento{
    padding-left: 6% !important;
  } 
  .texto-azul {
    letter-spacing: -0.03em !important;
  }  
}

/*
----------------------
  Section Footer 
----------------------
*/

.footer-section {
    font-size: 9px;
    text-transform:uppercase;
    letter-spacing:.1em;  
    text-align: center;
    background-color: #000000;
    padding: 18px 0 1px;
    overflow: hidden;
    color: #a0906f;
}

.footer-section hr {
  padding-top: 6px;
  background-color: rgb(0, 0, 0) !important;
  margin-top: 0rem !important;
  margin-bottom: 0rem !important;
  border: 1;
  border-top: 1px solid #a0906f !important;
}

.cursiva-azul {
  font-family: 'ReenieBeanie-Regular' !important;
  color: #6ba7ae;
  font-size: 26px;  
}

/*
--------------------------
PAGE SOCIAL EDUCATION
--------------------------
*/

.socialeducation {
  background-color: rgb(0, 0, 0);
  padding-top: 50px;
}

.socialeducation h3 {
  padding-bottom: 20px;
}

.socialeducation h4 {
  padding-bottom: 20px;
}

.socialeducation p {
  text-align: justify;
}

.socialeducation img {
  display:block;
  margin:auto;
  height: 450px;
  width: 600px;
}

@media screen and (max-width: 800px){
  .socialeducation img {
    display:block;
    margin:auto;
    height: 100%;
    width: 100%;
  }

  .socialeducation .container {
    width: 100% !important;
    padding-right: 40px;
    padding-left: 35px;
    margin-right: auto;
    margin-left: auto;
  }  

  h3 {
    text-align: center;
  }

  h4 {
    padding-top: 30px;
  }
}

/*
--------------------------
PAGE DIGITAL REVOLUCION
--------------------------
*/

.digitalrevolucion {
  background-color: rgb(0, 0, 0);
  padding-top: 50px;
}

.digitalrevolucion h3 {
  padding-bottom: 20px;
}

.digitalrevolucion h4 {
  padding-bottom: 20px;
}

.digitalrevolucion p {
  text-align: justify;
}

.digitalrevolucion img {
  display:block;
  margin:auto;
  height: 500px;
  width: 600px;
}

@media screen and (max-width: 800px){
  .digitalrevolucion .container {
    width: 100% !important;
    padding-right: 40px;
    padding-left: 35px;
    margin-right: auto;
    margin-left: auto;
  }  
  
  h4 {
    text-align: center;
  }  
}

/*
--------------------------
PAGE TECHNOLOGICALTOOLS
--------------------------
*/

.technologicaltools {
  background-color: rgb(0, 0, 0);
  padding-top: 50px;
}

.technologicaltools h2 {
  padding-top: 20px;
}

.technologicaltools h3 {
  padding-bottom: 20px;
  text-align: center;
}

.technologicaltools h4 {
  padding-top: 30px;
  padding-bottom: 20px;
}

.technologicaltools p {
  text-align: justify;
}

.technologicaltools li {
  color:#6ba7ae;
}

.technologicaltools img {
  display:block;
  margin:auto;
  height: 500px;
  width: 600px;
}

@media screen and (max-width: 800px){
  .technologicaltools img {
    display:block;
    margin:auto;
    height: 200px;
    width: 100%;
  }
  .technologicaltools .container {
    width: 100% !important;
    padding-right: 40px;
    padding-left: 35px;
    margin-right: auto;
    margin-left: auto;
  }   
}

/*
--------------------------
BUTTOM RETURN
--------------------------
*/

.link-arriba {
  display: inline-block;
  padding: 20px;
  background: #6e045d;
  font-size: 20px;
  color: #fff;
  cursor: pointer;
  position: fixed !important;
  bottom: 370px !important; /* distancia vertical */
  right: 0;
}
.link-regresar {
  display: inline-block;
  padding: 20px;
  background: #024959;
  font-size: 20px;
  color: #fff;
  cursor: pointer;
  position: fixed !important;
  bottom: 300px !important; /* distancia vertical */
  right: 0;
}
.link-abajo {
  display: inline-block;
  padding: 20px;
  background: #c1df18;
  font-size: 20px;
  color: #fff;
  cursor: pointer;
  position: fixed !important;
  bottom: 230px !important; /* distancia vertical */
  right: 0;
}

@media screen and (max-width: 800px){
  .link-arriba {
    display: inline-block;
    padding: 10px;
    background: #6e045d;
    font-size: 10px;
    color: #fff;
    cursor: pointer;
    position: fixed !important;
    bottom: 315px !important; /* distancia vertical 335px*/
    right: 0;
  }  
  .link-regresar {
    display: inline-block;
    padding: 10px;
    background: #024959;
    font-size: 10px;
    color: #fff;
    cursor: pointer;
    position: fixed !important;
    bottom: 280px !important;  /*300px*/
    right: 0;
  }
  .link-abajo {
    display: inline-block;
    padding: 10px;
    background: #c1df18;
    font-size: 10px;
    color: #fff;
    cursor: pointer;
    position: fixed !important;
    bottom: 245px !important; /* distancia vertical 265px*/
    right: 0;
  }  
}

/* 
********************************************************************
                          PAGES INFORMATION
********************************************************************
*/

/*
--------------------------
PAGE HISTORY COMPUTERS
--------------------------
*/

.historycomputers .container {
  color: #8fb464 !important;
  margin-top: 40px;
  text-align: justify;
}

.historycomputers li {
  /* font-family: 'ReenieBeanie-Regular' !important; */
  text-align: left;
  color: #14adcf;
  font-size: 16px;
}

.historycomputers img {
  width: 300px;
  height: 260px;
  display:block;
  margin:auto;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
}

@media screen and (max-width: 800px){
  .historycomputers .container {
    width: 100% !important;
    padding-right: 40px;
    padding-left: 35px;
    margin-right: auto;
    margin-left: auto;
    font-size: 12px;   
  }   
  .historycomputers img {
    padding-left: 10px;
    padding-right: 40px;
  }  
}

/*
--------------------------
PAGE NANOTECNOLOGY
--------------------------
*/

.nanotechnology .container {
  color: #8fb464 !important;
  margin-top: 40px;
  text-align: justify;
}

.nanotechnology h2 {
  font-family: 'ReenieBeanie-Regular' !important;
  text-align: left;
  color: #6ba7ae;
  font-size: 26px;
}

.nanotechnology li {
  /* font-family: 'ReenieBeanie-Regular' !important; */
  text-align: left;
  color: #6ba7ae;
  font-size: 16px;
}

.nanotechnology li {
  text-align: left;
}

.nanotechnology img {
  width: 500px;
  height: 450px;
  display:block;
  margin:auto;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
}

.nanotechnology .imgbarbijo {
  width: auto; 
  height: 750px;
}

@media screen and (max-width: 800px){

  .nanotechnology .container {
    width: 100% !important;
    padding-right: 40px;
    padding-left: 35px;
    margin-right: auto;
    margin-left: auto;
  }   
  .nanotechnology img {
    width: 300px;
    height: 260px;
  }
  .nanotechnology .imgbarbijo {
    width: 300px;
    height: 300px;
  }    
}

/*
--------------------------
PAGE HARDWARE
--------------------------
*/

.hardware .container {
  color: #8fb464 !important;
  margin-top: 40px;
  text-align: justify;
}

.hardware li {
  /* font-family: 'ReenieBeanie-Regular' !important; */
  font-size: 16px !important;
  text-align: left;
  color: #6ba7ae;
  font-size: 20px;
}

.hardware h2 {
  text-align: center;
  color: #ff205f;
  font-size: 18px;
} 

.hardware h3 {
  text-align: center;
  text-decoration: underline;
} 

.hardware .txtdeco1  {
  text-align: left;
  /* color: hsl(163deg 75% 68% / 77%) !important;  */
  font-size: 18px;
  text-decoration: underline;
}

.hardware .txtdeco2  {
  text-align: left;
  color: hsl(163deg 75% 68% / 77%) !important; 
}

.hardware .box  {
  box-shadow: 0 0 0 0.1em #a0906f,
  0 0 0 0.2em #6ba7ae;
  margin: 4em;
  padding:1em;
}

.hardware img {
  width: 500px;
  height: 450px;
  display:block;
  margin:auto;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;  
}

.hardware .img-arq-vn {
  width: auto;
  height: auto;
  display:block;
  margin:auto;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;  
}

.hardware .img-procesador {
  width: auto;
  height: auto;
  display:block;
  margin:auto;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;  
}

.hardware .advertencia  {
  box-shadow: 0 0 0 0.1em #eb1919,
  0 0 0 0.2em #f1f9fa;
  margin: 4em;
  padding:1em;
}

.hardware .bto-personalizdo {
  text-decoration:none;
  text-align: left;
  font-size: 12px;
  color:#6ba7ae;
  margin-right:10px;
  margin-left: 10px;
  padding-top:5px;
  padding-bottom:5px;
  padding-left:10px;
  background-color: transparent;
  border-width: 1px;
  border-style: solid;
  border-color: #b4b2b2;
}

@media screen and (max-width: 800px){

  .hardware .container {
    width: 100% !important;
    padding-right: 40px;
    padding-left: 35px;
    margin-right: auto;
    margin-left: auto;
  }    
  .hardware img {
    width: 100%;
    height: 100%;
  }  
  .hardware .img-arq-vn {
    width: 300px;
    height: auto;
    display:block;
    margin:auto;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px; 
  }
  .hardware .img-procesador {
    width: 300px;
    height: auto;
    display:block;
    margin:auto;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px; 
  }
  .hardware .bto-personalizdo {
    text-decoration:none;
    text-align: left;
    font-size: 12px;
    color:#6ba7ae;
    margin-right:10px;
    margin-left: 10px;
    display: block;
    padding-top:5px;
    padding-bottom:5px;
    padding-left:10px;
    background-color: transparent;
    border-width: 1px;
    border-style: solid;
    border-color: #b4b2b2;
  }
}

/*
--------------------------
PAGE SOFTWARE
--------------------------
*/
.software .container {
  color: #8fb464 !important;
  margin-top: 40px;
  text-align: justify;
}
.software hr.line-separacion-1 {
  background-color: #6e6e6e;
}

.software hr.line-separacion-2 {
  border-top: 1px dashed #6ba7ae;
}

.software li {
  /* font-family: 'ReenieBeanie-Regular' !important; */
  text-align: left;
  color: #6ba7ae;
  font-size: 16px;
}
.software li.align-left {
  /* display: inline-block; */
  text-align: left;
}
.software li.align-right {
  /* display: inline-block; */
  text-align: right;
  padding-left: 5px;
}
.software .classoft {
  font-family: 'ReenieBeanie-Regular' !important;
  text-align: center;
  color: #6ba7ae;
  font-size: 22px;
}
.software u.tituloU {
  font-size: 24px !important;
  text-align: center !important;
}

.software img {
  width: 500px;
  height: 450px;
  display:block;
  margin:auto;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;  
}

.software img.bios {
  width: auto;
  height: 250px;
}

.software img.capasHarware {
  width: auto;
  height: 250px;
  display:block;
  margin:auto;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;  
}
.software img.soft_prog {
  width: auto;
  height: 250px;
  display:block;
  margin:auto;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;  
}
.software img.so2 {
  width: auto;
  height: 250px;
  display:block;
  margin:auto;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;  
}
.software img.lucha-so {
  width: auto;
  height: 250px;
  display:block;
  margin:auto;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;  
}
.software u.titulo-referencias {
  font-size: 10px !important;
  margin-left: 15px !important;
}
.software .fuentes-referencias {
    font-size: 10px;
    margin-left: 15px !important;
}
.txt-img {
  font-size: 12px;
  color: #b4b2b2;
}

.code-algoritmo {
  color: #b1afaf;
}
.code-c {
  color: #1e89c7;
}

@media screen and (max-width: 800px){

  .software .container {
    width: 100% !important;
    padding-right: 40px;
    padding-left: 35px;
    margin-right: auto;
    margin-left: auto;
  }    
  .software img {
    width: 100%;
    height: 100%;
  }
  .software img.bios {
    width: auto;
    height: 180px;
    padding-left: 0px !important; 
  }
  .software img.tiposoft {
    width: auto;
    height: 70px;
    margin:auto;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;  
  } 
  .software u.titulo-referencias {
    font-size: 10px !important;
  }   
  .software .fuentes-referencias {
    font-size: 6px !important;
  }
  .software img.so2 {
    width: auto !important;
    height: 150px !important;  
  }  
  .software img.lucha-so {
    width: auto;
    height: 150px;
  }
  .software img.soft_prog {
    width: auto;
    height: 150px;
  }  

}


/*
--------------------------
PAGE SECURITY/RED
--------------------------
*/
.security .container {
  color: #8fb464 !important;
  margin-top: 40px;
  text-align: justify;
}
.security hr.line-separacion-1 {
  background-color: #6e6e6e;
}
.security hr.line-separacion-2 {
  border-top: 1px dashed #6ba7ae;
}
.security .estilo-text-1 {
  text-align: center;
  text-decoration: underline;
  /* text-decoration: underline wavy red; */
}
.security .estilo-text-2 {
  text-align: left;
  text-decoration: underline;
  font-weight: bold;
  font-size: 20px;
}

.security img {
  /* width: 500px;
  height: 450px; */
  display:block;
  margin:auto;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;  
}
.security .title-format-1 {
  text-decoration: underline !important;
}
.security img.format-img-1 {
  padding-right: 70px !important;
  width: 500px;
  height: 350px !important;
} 
.security img.format-img-2 {
  height: 100% !important;
  width: 700px !important;
}
.security .estilo-link {
  font-size: 16px;
  /* border: 1px solid #1E679A; */
  background-color: #313131;
} 
.security .topologias-tipos {
  width: 400px !important;
  height: 250px !important;
}
.security .colorblue {
  color:hsl(163deg 75% 68% / 77%);
}
.security .colorvioleta{
  color:#ffc107e1;
}

@media screen and (max-width: 800px){

  .security .container {
    width: 100% !important;
    padding-right: 40px;
    padding-left: 35px;
    margin-right: auto;
    margin-left: auto;
  }    
  .security img {
    width: 100%;
    height: 100%;
  }
  .security .estilo-text-1 {
    text-align: center;
    text-decoration: underline;
    /* text-decoration: underline wavy red; */
  }
  .security .estilo-text-2 {
    text-align: center;
    text-decoration: underline;
    font-weight: bold;
    font-size: 20px;
  }
  .security img.p2p {
    padding-left: 40px !important;
  }   
  .security img.format-img-1 {
    padding-right: 0px !important;
    width: 100% !important;
    height: 100% !important;
  } 
  .security img.format-img-2 {
    padding-right: 0px !important;
    width: 100% !important;
    height: 100% !important;
  }
  .security .topologias-tipos {
    width: 100% !important;
    height: 100% !important;
  }
}


/* 

ANIMACIONES 

*/

/* Animacion Texto Parpadeo */
@-moz-keyframes parpadeo{  
  0% { opacity: 1.0; }
  50% { opacity: 0.0; }
  100% { opacity: 1.0; }
}

@-webkit-keyframes parpadeo {  
  0% { opacity: 1.0; }
  50% { opacity: 0.0; }
   100% { opacity: 1.0; }
}

@keyframes parpadeo {  
  0% { opacity: 1.0; }
   50% { opacity: 0.0; }
  100% { opacity: 1.0; }
}
/* Fin animacion */
